import React, {Component} from 'react'; 
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import detectEthereumProvider from '@metamask/detect-provider'; 
import './ETHDenver.css'; 

import Web3 from 'web3';


const _contract = "0x50465c0207e3D31122D754d57540d54e9184DD6D"; 
const _abi = require('../abis/ethdenver.json'); 


class ETHDenver extends Component {
    constructor(props){
        super(props); 
        this.state = {
            inputText: "",
            walletConnected: true,
            walletAddress: null,
            hasMinted: false
        };
        this.textChange = this.textChange.bind(this); 

        this.iframeRef = React.createRef(); 


        this.handleAccountsChanged = this.handleAccountsChanged.bind(this);
        this.handleChainChange = this.handleChainChange.bind(this); 
        this.connectWallet = this.connectWallet.bind(this); 
        this.mintNft = this.mintNft.bind(this);
        this.confirmedMint = this.confirmedMint.bind(this); 
        this.linkWeb3 = this.linkWeb3.bind(this); 
    }
    
    handleAccountsChanged(accounts){
        if(accounts[0]){
            //this.setState({walletConnected: true, walletAddress: accounts[0]}); 
            if(this.iframeRef.current.contextWindow)this.iframeRef.current.contextWindow.loadText(); 
        }
        else{
            this.setState({walletConnected: false, walletAddress: null}); 
        }
    }

    handleChainChange(chainId){
        if(chainId == 1 && this.state.walletAddress){
            this.setState({walletConnected: true}); 
            this.iframeRef.current.contextWindow.loadText(); 
        }
        else{
            this.setState({walletConnected: false}); 
            alert('Please connect to the Ethereum Mainnet')
        }
    }

    async linkWeb3(){
        const provider = await detectEthereumProvider(); 

        this.web3 = null; 
        if(provider){
            this.web3 = new Web3(window.ethereum); 
            await provider.request({ method: 'eth_requestAccounts' });
            provider.on('accountsChanged', this.handleAccountsChanged);
            provider.on('chainChanged', this.handleChainChange);
            this.contractInstance = new this.web3.eth.Contract(_abi, _contract); 
            this.web3.eth.getChainId().then((id)=>{
                if(id == 1){
                    this.web3.eth.getAccounts().then((addr) => {
                        //this.setState({walletConnected: true, walletAddress: addr});
                        if(this.iframeRef.current)this.iframeRef.current.contextWindow.loadText(); 
                    })
                }
                else{
                    alert("Please connect to Ethereum Mainnet!"); 
                }
            }); 

            //Check if address has already minted before 
            this.contractInstance.methods.hasMinted(window.ethereum.selectedAddress).call().then(res => {
                this.setState({hasMinted: false}); //change false to res var 
            })
            .catch((err) => {
                console.log(err);
            })
        }
        else{
            alert("Please Install Metamask!"); 
        }
     
            
        
        
    }

    componentDidMount(){
       this.linkWeb3()

    }

    componentWillUnmount(){
        window.ethereum.removeListener('accountsChanged', this.handleAccountsChanged); 
        window.ethereum.removeListener('chainChanged', this.handleChainChange);
    }

    textChange(e){
        this.setState({inputText: e.target.value}, () => {
            console.log("TEXT: ", this.state.inputText); 
        }); 
        this.iframeRef.current.contentWindow.drawImage(e.target.value, '#0'); 
    }

    confirmedMint(text){
        this.contractInstance.methods.mintSticker(text).send({from: window.ethereum.selectedAddress})
            .then((res) => {console.log(res); this.setState({hasMinted: true})})
            .catch((err) => {console.log(err)}); 
    }

    mintNft(){
        var text = this.state.inputText; 
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h3>Mint Confirmation</h3>
                  <p>Confirm the text you have entered:<br />{`"${text}"`}</p>
                  <button onClick={onClose} className="button button-danger button-wide-mobile button-sm">No</button>
                  <button
                    className="button button-primary button-wide-mobile button-sm"
                    id="yes-button"
                    onClick={() => {
                      this.confirmedMint(text); 
                      onClose();
                    }}
                  >
                    Mint, please!
                  </button>
                </div>
              );
            }
          });
    }

    async connectWallet(){
        const accounts = await window.ethereum.send("eth_requestAccounts"); 
        const currentChain = await this.web3.eth.getChainId(); 
        if(currentChain != 1){
            alert("Please change to Ethereum Mainnet"); 
        }
        else{
            this.setState({walletConnected: true, walletAddress: accounts[0]}); 
        }
    }

    render(){
        return(
            <section
                className={"hero section center-content illustration-section-01"}
            >
                <div className="container-sm">
                    <div className={"hero-inner section-inner"}>
                        <div className={"hero-content"} style={{paddingBottom: "10%"}}>
                            <iframe src="/template.html" style={{width: '50vh', height: "50vh", maxWidth: "100%", maxHeight: "100%"}} id="nftPreview" ref={this.iframeRef}></iframe>
                            
                            <div style={{display: "flex", flexDirection: "column", width: "20rem", margin: 'auto'}}>
                                <button className="button button-primary button-wide-mobile button-sm" hidden={this.state.walletConnected} onClick={this.connectWallet} style={{margin: 'auto', width: "65%"}}>Connect Wallet</button>
                                <input type="text" className="mintInput" hidden={!this.state.walletConnected} placeholder="Your name is?" onChange={this.textChange} style={{margin: 'auto', width: "90%", marginBottom: "0.5rem", maxWidth: "70vw"}}/>
                                <button className="button button-primary button-wide-mobile button-sm" hidden={!this.state.walletConnected} style={{width: "10rem", margin: 'auto', maxWidth: "90%"}} onClick={this.mintNft} disabled={this.state.hasMinted}>{this.state.hasMinted ? ("Already claimed") : ("Mint")}</button>
                            </div>
                         
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ETHDenver; 