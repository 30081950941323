import React, { useRef, useEffect} from 'react';
import { useLocation, Switch, Route} from 'react-router-dom';
import AppRoute from './utils/AppRoute';

import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';
// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import ETHDenver from './views/ETHDenver'; 
// import Dashboard from './views/Dashboard'; 
import Proposal from './views/Proposal/Proposal';
//Css 
import './App.css';
import Dungeon from './views/Dungeon';





// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};


//Particles Background Effect 
// import Particles from 'react-tsparticles';




const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (

      <ScrollReveal
        ref={childRef}
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute exact path="/ethdenver" component={ETHDenver} layout={LayoutDefault} /> 
            <AppRoute exact path="/dungeon" component={Dungeon} layout={LayoutDefault} />




          </Switch>
        )} />

  );
}

export default App;