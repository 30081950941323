import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import PixelSlideshow from '../../utils/PixelSlideShow';

//Import images
import firstImage from '../../assets/images/slideshow/1.PNG'; 
import secImage from '../../assets/images/slideshow/2.JPG'; 
import image3 from '../../assets/images/slideshow/3.JPG'; 
import image4 from '../../assets/images/slideshow/4.JPG';
import image5 from '../../assets/images/slideshow/5.JPG';
import image6 from '../../assets/images/slideshow/6.JPG';
import image7 from '../../assets/images/slideshow/7.jpg';
import image8 from '../../assets/images/slideshow/8.png';
import image9 from '../../assets/images/slideshow/9.png';
import image10 from '../../assets/images/slideshow/10.png'; 
import image11 from '../../assets/images/slideshow/11.jpg';
import image12 from '../../assets/images/slideshow/12.jpg';
import image13 from '../../assets/images/slideshow/13.jpg';
import image14 from '../../assets/images/slideshow/14.jpg';
import image15 from '../../assets/images/slideshow/15.jpg';
import image16 from '../../assets/images/slideshow/16.jpg';
import image17 from '../../assets/images/slideshow/17.jpg';
import image18 from '../../assets/images/slideshow/18.jpg';
import image19 from '../../assets/images/slideshow/19.jpg'; 



const slide_images = [
  firstImage,
  secImage,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14, 
  image15,
  image16,
  image17,
  image18,
  image19
];

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Million Dollar Living 
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
              A rewarding community built by never-ending investments, backed by a utility/yield farm token $MDLT. To have governance over the DAO you must be a token holder or holder of any of our NFT projects.
                </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile href="https://app.uniswap.com/swap" >
                    Invest Now
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            <PixelSlideshow images={slide_images} w={600} h={500}/>
          </div>
         
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;