import React, {} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FooterLogo from './partials/FooterLogo';


//MDLDAO LOGO
import MDLDAOText from '../../assets/images/MDLDAOText.png'

//Import trackers
import CbHolder from '../../assets/images/trackers/cbHolderSupply.svg';
import DdHolder from '../../assets/images/trackers/ddHolderSupply.svg';
import MdldaoFees from '../../assets/images/trackers/mdldaoFees.svg';
import MdldaoTreasury from '../../assets/images/trackers/mdldaoTreasury.svg'; 
import MdltHolders from '../../assets/images/trackers/mdltHolders.svg'; 

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

const Trackers = () => {
  let all = [
    CbHolder,
    DdHolder,
    MdldaoFees,
    MdldaoTreasury,
    MdltHolders 
  ]; 

  return (
    <>
      {
        all.map((value, index) => {
          return <img width="100%" height="100%" src={value}/>
        })
      }
    </>
  )
}
 
const Footer = ({
  className,
  topOuterDivider,
  topDivider,
  ...props
}) => {

  const classes = classNames(
    'site-footer center-content-mobile',
    topOuterDivider && 'has-top-divider',
    className
  );

  return (
    <footer
      {...props}
      className={classes}
    >
      <div className="container" style={{maxWidth: "none", width: "100%"}}>
        <div className={
          classNames(
            'site-footer-inner',
            topDivider && 'has-top-divider'
          )} style={{width: "100%"}}>
          <div className="footer-top trackers-grid">
            {/* <Trackers /> */}
          </div>
          <div className="footer-bottom space-center text-xxs" style={{width: "100%"}}>
            <FooterLogo />
            <img src={MDLDAOText} style={{width: "25%",}}/>
          </div>
          
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;