import React from 'react';


export default function Dungeon(props) {
    return(
        <div style={{display: "flex", height: "85vh", width: "100%", alignItems: "center", justifyContent: "center", marginTop: "5%"}}>
            <iframe style={{minWidth: "525px"}} width="50%" height="100%" src="https://viewer.generativedungeon.com/1573" allowFullScreen="" scrolling="yes"></iframe>
        </div>
        
    );
}