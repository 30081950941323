import React, { Component } from 'react'; 
import {createCanvas, loadImage, createImageData} from 'canvas';


//Some basic functions 
const group = (arr, len) => {
    let count = 0; 
    let result = []; 

    let iterations = arr.length / len; 

    for(let i = 0; i<iterations; i++){
        let new_array = arr.slice((i*len), ((i+1)*len));
        result.push(new_array); 
    }
    return result; 
}


function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

const delay = async(ms) => {
    return new Promise((resolve)=>setTimeout(resolve, ms)); 
}

const ungroup = (arr) => {
    let result = []; 

    for(let i = 0; i<arr.length; i++){
        for(let x = 0; x<arr[i].length; x++){
            result.push(arr[i][x]); 
        }
    }

    return result; 
}



class PixelSlideshow extends Component{

    constructor(props){
        super(props);

        this.state = {
            images: props.images,
            curIndex: 0,
            w: props.w, 
            h: props.h,
            transitionActive: false 
        }
        this.canvasEl = React.createRef(); 
        this.transitionNew = this.transitionNew.bind(this); 
    }

    async transitionNew(_old, _new, w, h){
        await this.setState({transitionActive: true});
        //Define Square sizes of odd or evenly width / height images
        let squareW = w * 0.05; 
        let squareH = h * 0.05; 

        let index = 0; 
        let squareLength = w/squareW;
        
        let _oldCtx = await _old.getContext('2d');
       
        
        let iterations = (w * h) / (squareW * squareH);
        
        console.log(iterations)

        let order = []; 


        //Divide new image into squares
        for(let i = 0; i<iterations; i++){

            let ox = (index % squareLength) * squareW; 
            let oy = Math.floor(index / squareLength) * squareH; 

            ox = ox 
            oy = oy 

            //_oldCtx.putImageData(_new, ox/w, oy/h, ox, oy, squareW, squareH); 
            index++;
            

            order.push({
                ox: ox/w,
                oy: oy/h,
                ox2: ox ,
                oy2: oy, 
                paintWidth: squareW, 
                paintHeight: squareH
            });
        }

        //shuffle array
        shuffle(order)

        for(let i = 0; i<order.length; i++){
            let cur = order[i]; 

            _oldCtx.putImageData(_new, cur.ox, cur.oy, cur.ox2, cur.oy2, cur.paintWidth, cur.paintHeight);
            await delay(15); 
        }

        this.setState({transitionActive: false}); 

    }

    async initialRender(){
        let context = this.canvasEl.current.getContext('2d');
        let curImage = await loadImage(this.state.images[0]);

        context.drawImage(curImage, 0, 0, this.state.w, this.state.h);

        this.loop(); 
    }

    async loop(){
        let index = 1; 

        let isOffPage = false; 
        
        window.onblur=()=>{
            console.log("Left page")
            isOffPage = true; 
        }
        window.onfocus=()=>{
            console.log("Is back :)")
            isOffPage=false; 
        }

        while(true){
            if(this.state.transitionActive || isOffPage){
                await delay(1000);
                continue; 
            } 
            let w = this.state.w
            let h = this.state.h

            let curImage = await loadImage(this.state.images[index]);
            let currentCanvas = createCanvas(w, h); 
            let context = currentCanvas.getContext('2d');
            context.drawImage(curImage, 0, 0, w, h); 

            let imageData = context.getImageData(0,0, w, h);

            await delay(8000); 

            requestAnimationFrame(()=>{this.transitionNew(this.canvasEl.current, imageData, w, h)});

            let future_index = index+1; 
            if(this.state.images[future_index]){
                index++;
            }
            else{
                index=0; 
            }
        }
    }

    componentDidMount(){
        this.initialRender(); 
    }

    render(){
        return(
            <>
            <canvas ref={this.canvasEl} width={this.state.w} height={this.state.h} style={{width: '50vh', height: "auto", maxWidth: "100%", maxHeight: "100%"}}></canvas>
            </>
        )
    }
}


export default PixelSlideshow;