import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: '$MDLT Tokenomics',
    paragraph: 'Yield farm backed token with utility. Governed by MDLDAO holders and profits are reflected towards holders. We are utilizing art with NFTs to produce a flowing ecosystem for all MDLDAO holders.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                
                <h3 className="mt-0 mb-12">
                  CandyBandits NFT
                  </h3>
                <p className="m-0">
                505 CandyBandits make up this collection MDLDAO has created. These are all hand drawn with no generative aspects to them at all. They are able to be staked for $MDLT, and we are using this to generate funds for future development. These will continue to release every week once our token, $MDLT has released. The future development of this project will be voted on by DAO holders. 
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/CandyBandits.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                
                <h3 className="mt-0 mb-12">
                  DigiDynos NFT
                  </h3>
                <p className="m-0">
                DigiDynos is 1 of our 2 Generative art projects being released. This will consist of 7,777 Dynos and 500 being a Genesis version which can be staked via the Dyno Dashboard. This project is built all on-chain and everything from the mint fees to future dyno releases will be utilized within our $MDLT ecosystem.
                  <br /> < br />
                DigiDynos Wallet (30% of sales) <br />
                Developer team (25%) <br />
                MDLDAO receives (45%) <br />
                  <br />
                The MDLDAO % from the mint fees is then split between 5% to $MDLT buybacks, 10% for MDLDAO specific marketing, 25% in put into our yield farming pool, and 5% goes to our founding fathers. 
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/DigiDynos.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                
                <h3 className="mt-0 mb-12">
                  $MDLT Token
                  </h3>
                <p className="m-0">
                $MDLT is both the token of governance over our MDLDAO as well as the utility for all open-source collaborations within the metaverse. We back this token by yield farm techniques, a staked ETH treasury, and a treasury of NFT projects. While all of our NFT projects are built on-chain this allows us to involve our token in more ways than most allowing for ways to burn, utilize, and increase the value of our token.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/MDLT.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;